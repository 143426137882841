import { Component, OnInit } from '@angular/core';
import { of as observableOf } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'm-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  title: string;
  public display: boolean = false;
  constructor() { }

  ngOnInit() {
  }

  // Function to show modal content
  public show() {
    this.display = true;
  }

  // Function to hide modal content
  public hide() {
    this.display = false;
  }

  // Function to hide modal content with delay
  public hideWithDelay(delayTime ?:number) {
    observableOf(null).pipe(delay(delayTime?delayTime:2000)).subscribe(() => {
      this.display = false;
    });
  }

}
