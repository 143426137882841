import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import { Observable } from 'rxjs';
import {environment} from "../../../../../environments/environment";

@Injectable()
export class LoginGuard implements CanActivate {

    constructor(private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {


        if (localStorage.getItem('user')) {
            return true;
        } else {
            this.router.navigate(['/login']);

            return false;
        }

    }
}