// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  isMockEnabled: true, // You have to switch this, when your real back-end is done
  firebase : {
    apiKey: "AIzaSyCM5L5ytrC455Ns3tSjwPKxY389npMYzt4",
    authDomain: "spotivity-211808.firebaseapp.com",
    databaseURL: "https://spotivity-211808.firebaseio.com",
    projectId: "spotivity-211808",
    storageBucket: "spotivity-211808.appspot.com",
    messagingSenderId: "982335608689",
    appId: "1:982335608689:web:e4cdced7c08d8d02f1c8d0",
    measurementId: "G-QCEV5ZHTSB"
  },
youtubeEmbedLink:'https://youtube.com/embed/',
youtubeWatchLink:'https://www.youtube.com/watch?v=',
youtubeLink:'https://youtu.be',
};
