import {ConfigModel} from '../core/interfaces/config';

export class MenuConfig implements ConfigModel {
    public config: any = {};

    constructor() {
        this.config = {
            header: {
                self: {},
                items: [
                    {
                        title: 'spotivity Admin'
                    }
                ]
            },
            aside: {
                self: {},
                items: [
                    {
                        title: 'Dashboard',
                        desc: 'Programs listing, details and action',
                        root: true,
                        icon: 'flaticon-line-graph',
                        page: '/dashboard',
                        translate: 'MENU.DASHBOARD'
                    },
                    {
                        title: 'Programs',
                        desc: 'Programs listing, details and action',
                        root: true,
                        icon: 'flaticon-line-graph',
                        page: '/programs',
                        translate: 'MENU.DASHBOARD'
                    },
                    {
                        title: 'Program Request Listing',
                        desc: 'Programs  request listing',
                        root: true,
                        icon: 'flaticon-line-graph',
                        page: '/programRequestList',
                        translate: 'MENU.DASHBOARD'
                    },
                    {
                        title: 'Users',
                        desc: 'Users listing, details and action',
                        root: true,
                        icon: 'flaticon-line-graph',
                        page: '/users',
                        translate: 'MENU.DASHBOARD'
                    },
                    {
                        title: 'Parents',
                        desc: 'Parents listing, details and action',
                        root: true,
                        icon: 'flaticon-line-graph',
                        page: '/parents',
                        translate: 'MENU.DASHBOARD'
                    },
                    {
                        title: 'Provider Admin',
                        desc: 'Program Owners listing, details and action',
                        root: true,
                        icon: 'flaticon-line-graph',
                        page: '/agencyDirectors',
                        translate: 'MENU.DASHBOARD'
                    },
                    {
                        title: 'Provider Admin Request Management',
                        desc: 'Program Owners listing, details and action',
                        root: true,
                        icon: 'flaticon-line-graph',
                        page: '/request-management',
                        translate: 'MENU.DASHBOARD'
                    },
                    {
                        title: 'Buckets',
                        desc: 'Bucket list, detail view and add new bucket',
                        root: true,
                        icon: 'flaticon-line-graph',
                        page: '/buckets',
                        translate: 'MENU.DASHBOARD'
                    },
                    {
                        title: 'Notification',
                        desc: 'Send notifications based on program name',
                        root: true,
                        icon: 'flaticon-line-graph',
                        page: '/notifications',
                        translate: 'MENU.DASHBOARD'
                    },
                    {
                        title: 'Listing Issue',
                        desc: 'Student request listing',
                        root: true,
                        icon: 'flaticon-line-graph',
                        page: '/listingIssue',
                        translate: 'MENU.DASHBOARD'
                    },
                    {
                        title: 'Wallet',
                        desc: 'Student request listing',
                        root: true,
                        icon: 'flaticon-line-graph',
                        page: '/wallet',
                        translate: 'MENU.DASHBOARD'
                    },
                    {
                        title: 'General Work',
                        desc: 'Student request listing',
                        root: true,
                        icon: 'flaticon-line-graph',
                        page: '/general-work',
                        translate: 'MENU.DASHBOARD'
                    },
                    {
                        title: 'Keywords',
                        desc: 'Student request listing',
                        root: true,
                        icon: 'flaticon-line-graph',
                        page: '/keywords',
                        translate: 'MENU.DASHBOARD'
                    },
                    {
                        title: 'Forum',
                        desc: 'Student request listing',
                        root: true,
                        icon: 'flaticon-line-graph',
                        page: '/forum',
                        translate: 'MENU.DASHBOARD'
                    },
                    {
                        title: 'Ad Management',
                        desc: 'Student request listing',
                        root: true,
                        icon: 'flaticon-line-graph',
                        page: '/ads-management',
                        translate: 'MENU.DASHBOARD'
                    },
                     {
                        title: 'Newsroom',
                        desc: 'Student request listing',
                        root: true,
                        icon: 'flaticon-line-graph',
                        page: '/newsroom',
                        translate: 'MENU.DASHBOARD'
                    },
                    {
                        title: 'New-Schools',
                        desc: 'School request listing',
                        root: true,
                        icon: 'flaticon-line-graph',
                        page: '/new-schools',
                        translate: 'MENU.DASHBOARD'
                    },
                    {
                        title: 'Flagged Videos',
                        desc: 'Flagged Videos listing',
                        root: true,
                        icon: 'flaticon-line-graph',
                        page: '/flagged-videos',
                        translate: 'MENU.DASHBOARD'
                    },
                    {
                        title: 'Revenue Share',
                        desc: 'Revenue Share listing',
                        root: true,
                        icon: 'flaticon-line-graph',
                        page: '/revenue-share',
                        translate: 'MENU.DASHBOARD'
                    },
                    {
                        title: 'Reward Stores',
                        desc: 'Store listing',
                        root: true,
                        icon: 'flaticon-line-graph',
                        page: '/store-management',
                        translate: 'MENU.DASHBOARD'
                    },
                ]
            }
        };
    }
}
