import {
	Component,
	OnInit,
	Input,
	ChangeDetectionStrategy,
	ChangeDetectorRef
} from '@angular/core';
import { LogsService } from '../../../../../core/services/logs.service';
import { LogData } from '../../../../../core/interfaces/log-data';
import { Observable } from 'rxjs';
import { NotificationService } from '../../../../../core/services/layout/notification.service';

@Component({
	selector: 'm-list-timeline',
	templateUrl: './list-timeline.component.html',
	styleUrls: ['./list-timeline.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListTimelineComponent implements OnInit {
	@Input() type: any;
	@Input() heading: any;

	@Input() logList: Observable<LogData[]>;

	notificationlist: any = [];
    page = 1
   
	constructor(private logsService: LogsService, private notificationService: NotificationService,private cd:ChangeDetectorRef) {}

	ngOnInit() {
		// call logs to http api
	 this.listofNotifications(this.page);
	 this.logList = this.notificationService.getData(this.page);
	}

	listofNotifications(page){
		this.notificationService.getNotificationList(page).subscribe((response:any) =>{
				let data: any = response['result'].notificationList;
				if (data && data.length > 0) {
					data.forEach((ele, ind) => {
						  this.notificationlist.push(ele);
					  });
				}
				this.cd.detectChanges()
	})
}


	onScroll(event,type) {

     if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
		this.page++;
		this.listofNotifications(this.page);
		}
	}
}
