import {
	Component,
	OnInit,
	HostBinding,
	HostListener,
	Input,
	ChangeDetectionStrategy,
	ChangeDetectorRef
} from '@angular/core';
import { NotificationService } from '../../../../../core/services/layout/notification.service';
import { Observable } from 'rxjs';
import { LogData } from '../../../../../core/interfaces/log-data';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
@Component({
	selector: 'm-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent implements OnInit {
	notCount : any;
	@HostBinding('class')
	// tslint:disable-next-line:max-line-length
	classes = 'm-nav__item m-topbar__notifications m-topbar__notifications--img m-dropdown m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-center 	m-dropdown--mobile-full-width';

	@HostBinding('attr.m-dropdown-toggle') attrDropdownToggle = 'click';
	@HostBinding('attr.m-dropdown-persistent') attrDropdownPersisten = 'true';

	@Input() animateShake: any;
	@Input() animateBlink: any;
	disableRedDot: boolean;
	notificationlist: any = [];
	page = 1
    viewLoading: boolean = false;
	constructor(private notificationService: NotificationService,private router: Router,private cd: ChangeDetectorRef) {
		// animate icon shake and dot blink
		setInterval(() => {
			this.animateShake = 'm-animate-shake';
			this.animateBlink = 'm-animate-blink';
		}, 3000);
		setInterval(() => (this.animateShake = this.animateBlink = ''), 6000);
		this.router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				this.notificationCount();
				
            }			
        });
	}

	ngOnInit(){
		this.notificationCount();
	}

	notificationCount(){
		this.notificationService.getNotificationCount().subscribe((response:any) => {
			 this.notCount = response.result.notificationCount;
			 if(this.notCount != 0){
				 this.disableRedDot = true
			 } else {
				 this.disableRedDot = false
			 }
			 this.cd.detectChanges()
		})
	}

	listofNotifications(page){
		this.viewLoading = true;
		this.notificationService.getNotificationList(page).subscribe((response:any) =>{
				let data: any = response['result'].notificationList;
				if (data && data.length > 0) {
					data.forEach((ele, ind) => {
						  this.notificationlist.push(ele);
					  });
				}
				this.viewLoading = false;
				this.notificationCount()
				this.cd.detectChanges()
	})
}


	onScroll(event,type) {
     if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
		this.page++;
		this.listofNotifications(this.page);
		}
	}
	openDropdown(){
		this.notificationlist = [];
		this.listofNotifications(1)
	}
}
