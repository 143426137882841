import { Injectable } from '@angular/core';
import * as globals from '../../../global';
import { HttpUtilsService } from '../../../content/pages/components/_core/utils/http-utils.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LogData } from '../../interfaces/log-data';
import { UtilsService } from '../utils.service';


@Injectable()

export class NotificationService {

    page_no = '?page_no=';
    page_size = '&page_size=10'

    constructor(private http: HttpClient , private utils: UtilsService){}

    getNotificationCount() {
        return this.http.get(globals.base_url_dashboard + `dashboard/getNotificationCount`);
    }

    getNotificationList(page){
        return this.http.get(globals.base_url_dashboard + `dashboard/getNotificationList` + this.page_no + page + this.page_size)
    }

    getData(page): Observable<any> {
        let url = globals.base_url_dashboard + `dashboard/getNotificationList` + this.page_no + page + this.page_size;
		return this.http
			.get(url)
			.pipe(tap((message: LogData[]) => { }));
	}

}