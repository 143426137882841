import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[mPreventZero]'
})
export class PreventZeroDirective {

  regexStr = '^[1-9]*$';

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInput(event: Event) {
    const input = this.el.nativeElement as HTMLInputElement;
    const value = input.value;

    // Check if the input starts with '0' or space and has no other characters before it.
    if (/^(0| )/.test(value) && value.trim().length === 1) {
      input.value = ''; // Clear the input if it starts with '0' or space and has no other characters before it.
    }
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    // Allow backspace and space if the input is not empty
    if (event.key === 'Backspace') {
      return;
    }

    const input = this.el.nativeElement as HTMLInputElement;
    const value = input.value;

    // Check if the input starts with '0' or space and has no other characters before it.
    if (/^(0| )/.test(value) && value.trim().length === 1) {
      event.preventDefault();
    }

    // Check if the input is not a number (integer)
    if (!/^[0-9]$/.test(event.key)) {
      event.preventDefault();
    }
  }

}
